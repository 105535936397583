
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="业主名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入业主名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="tel">
          <el-input v-model="formModel.tel" placeholder="请输入手机号码" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="所在园区" prop="park">
          <el-input v-model="formModel.park" placeholder="请输入所在园区" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="楼栋/单元/房号" prop="building">
          <el-input v-model="formModel.building" placeholder="请输入楼栋/单元/房号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="面积" prop="area">
          <el-input v-model="formModel.area" placeholder="请输入面积" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="入住时间" prop="checkinTime">
          <el-date-picker
      v-model="formModel.checkinTime"
      align="right"
      type="date"
      placeholder="选择日期"
      :picker-options="pickerOptions">
    </el-date-picker>
        </el-form-item>
        <el-form-item label="应缴物业费" prop="propertyCosts">
          <el-input v-model="formModel.propertyCosts" placeholder="请输入应缴物业费" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import ownerInfoApi from "@/api/base/ownerInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [
          { required: true, message: "业主名称不能为空", trigger: "blur" }
        ],
        tel: [{ required: true, message: "手机号码不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return ownerInfoApi.add(self.formModel);
            } else {
              return ownerInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return ownerInfoApi.create();
      } else {
        return ownerInfoApi.edit(self.menuId);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>